import React from 'react';

import Survey from './Containers/Survey/Survey';
import {CommonSurvey} from './Surveys/CommonSurvey';
import {MedicalHistoryFrau} from './Surveys/MedicalHistoryFrau';
import {BrowserRouter, Routes, Route} from 'react-router-dom';

import './App.css';
import GlobalStyles from './Utils/styles/global-styles';
import {SurveyProvider} from './Containers/SurveyProvider/SurveyProvider';

function App() {
  return (
    <>
      <GlobalStyles />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<SurveyProvider />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
