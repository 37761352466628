import {createGlobalStyle, css} from 'styled-components';
import customFonts from './fonts';
import {colors, fonts} from './theme';

const style = css`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  body {
    font-family: ${fonts.face.primary};
    color: ${colors.cloudBurst};
  }

  html {
    height: -webkit-fill-available;
  }

  main {
    width: 100%;
    max-width: 480px;
    padding-bottom: 48px;
    margin: 0 auto;
    /* box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1); */
    background-color: ${colors.white};
    overflow-x: hidden;
  }
`;

export default createGlobalStyle`
  ${style}
  ${customFonts}
`;
