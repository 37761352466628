
import React, {useEffect, useState} from 'react'
import {useSearchParams} from 'react-router-dom';
import { z } from 'zod'
import axios from 'axios'
import Survey from '../Survey/Survey';

export function SurveyProvider() {
  const [searchParams, setSearchParams] = useSearchParams();

  const [surveyLayout, setSurveyLayout] = useState<object | false>(false)

  const reactNativePostMessage = (data: object) => {
    try {
      // @ts-ignore
      window?.ReactNativeWebView?.postMessage(JSON.stringify(data));
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    console.log(process.env)
    const fetchData = async () => {

      const _surveyLayoutId = searchParams.get('surveyLayoutId')
      const _clinicId = searchParams.get('clinicId')
      const locale = searchParams.get('locale') ?? 'en'
      let surveyLayoutId: false | string = false
      let clinicId: false | string = false
      try {
        surveyLayoutId = z.string().uuid().parse(_surveyLayoutId)
        clinicId = z.string().uuid().parse(_clinicId)
        console.log(surveyLayoutId)
        console.log(clinicId)
      } catch (e) {
        console.error('error', e)
        // @ts-ignore
        reactNativePostMessage({ error: 'PARAMETER_ERROR'})
      }

      const response = await axios.get(`${process.env.REACT_APP_API_URL}/survey/${clinicId}/${surveyLayoutId}/`, {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': process.env.REACT_APP_API_KEY ?? ''
        }
      })

      console.log(response)

      if (response.status === 200 && !!response.data && !!response.data.data && !!response.data.data.surveyLayout) {
        console.log(response.data.data)

        const _surveyLayout = response.data.data.surveyLayout

        if (!_surveyLayout.title) {
          _surveyLayout.title = response.data.data.surveyName
        }
        _surveyLayout.locale = locale

        setSurveyLayout(_surveyLayout)
      }
    }

    fetchData()

  }, [])

  if (!surveyLayout) {
    return <React.Fragment />
  }

  return <Survey surveyJSON={surveyLayout} />
}