/* eslint-disable @typescript-eslint/no-unused-vars */
import styled from 'styled-components';
import {colors, fonts} from '../../Utils/styles/theme';

export const Content = styled.div`
  --primary: ${colors.cloudBurst};
  --primary-light: ${colors.cloudBurst}10;
  --red: ${colors.carnation};
  --red-light: ${colors.carnation}10;

  padding-left: 24px;
  padding-right: 24px;
  padding-top: 32px;
  padding-bottom: 100px;

  .sd-page {
    min-width: unset;
  }

  .sd-clearfix {
    div:first-child {
      min-width: unset !important;
    }
  }

  .sd-container-modern__title {
    flex-direction: column;
    row-gap: 12px;
  }
  .sd-container-modern__title,
  .sd-body__page,
  .sd-question {
    box-shadow: unset !important;
    padding-left: unset !important;
    padding-right: unset !important;
  }

  .sd-title {
    font-family: ${fonts.face.primary};
    font-style: normal;
    font-weight: 200;
    font-size: 34px;
    line-height: 36px;
    letter-spacing: -0.5px;
    color: ${colors.cloudBurst};
    span: nth-child(2) {
      display: inline-block;
      width: 20px !important;
    }
  }

  .sd-page__title {
    text-transform: uppercase;
    font-family: ${fonts.face.primary};
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.6px;
    color: ${colors.cerulean};
  }

  .sd-description,
  .sd-element__title,
  .sd-question__title,
  .sd-element__num {
    font-family: ${fonts.face.primary};
    font-weight: 700;
    font-size: 14px !important;
    line-height: 20px;
    color: ${colors.cloudBurst};
  }
  .sd-description {
    color: gray;
  }

  .sd-selectbase__label {
    gap: 16px;
  }
  .sd-item__control-label {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.2px;
  }

  .sd-action--negative {
  }

  .sd-input {
    box-shadow: unset;
    border-radius: 12px;
    font-family: ${fonts.face.primary};
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.2px;
    color: ${colors.cloudBurst};
  }

  .sd-input:focus {
    box-shadow: unset;
  }

  .sd-btn {
    background: linear-gradient(
      45deg,
      ${colors.cerulean} 0%,
      ${colors.areoBlue} 100%
    );
    border-radius: 20px;
    padding: 9px 28px !important;
    border: none;
    outline: none;
    flex-grow: unset !important;
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.1px;
  }

  .sd-body__navigation {
    justify-content: space-between;
  }
  .sd-btn:not(:disabled):hover,
  .sd-btn:not(:disabled):focus {
    box-shadow: unset;
    color: ${colors.cloudBurst};
  }

  .sd-navigation__prev-btn {
    background: unset;
    -webkit-box-shadow: inset 0px 0px 0px 2px ${colors.cerulean} !important;
    -moz-box-shadow: inset 0px 0px 0px 2px ${colors.cerulean} !important;
    box-shadow: inset 0px 0px 0px 2px ${colors.cerulean} !important;
    color: ${colors.cerulean};
  }

  .sd-input {
    .sd-text {
      border-radius: unset;
    }
  }

  .sd-checkbox {
    .sd-checkbox__decorator {
      width: 24px;
      height: 24px;
      border-radius: 6px;
      border: 1.2px solid ${colors.white};
      box-shadow: 2px 2px 2px ${colors.black}05,
        inset -3px -5px 3px ${colors.white}60,
        inset 0.962099px 1.9242px 7.69679px ${colors.black}15;
    }
  }

  .sd-checkbox--checked {
    .sd-checkbox__decorator {
      box-shadow: unset;
    }
  }

  .sd-radio {
    .sd-radio__decorator {
      width: 24px;
      height: 24px;
      border: 1.2px solid ${colors.white};
      box-shadow: 2px 2px 2px ${colors.black}05,
        inset -3px -5px 3px ${colors.white}60,
        inset 0.962099px 1.9242px 7.69679px ${colors.black}15;
    }
  }

  .sd-radio--checked {
    .sd-radio__decorator {
      box-shadow: unset;
    }
  }

  .sd-navigation__next-btn {
    color: ${colors.white};
  }

  .sd-matrixdynamic__remove-btn {
    margin-right: 20px;
  }

  .sd-matrixdynamic__footer {
    margin-left: 20px;
  }

  .sv-ranking-item__icon,
  .sv-ranking-item--ghost {
    fill: ${colors.cloudBurst};
  }

  .sv-ranking-item__index {
    background: ${colors.cloudBurst}10;
  }
  .sv-ranking-item:focus .sv-ranking-item__index {
    border-color: ${colors.cloudBurst};
  }

  .sd-root-modern {
    background-color: unset;
  }
  .sd-element--complex:not(.sd-element--collapsed)
    > .sd-element__header--location-top:after {
    height: 0;
  }

  .sd-table__cell--row-text {
    .sv-string-viewer {
      color: ${colors.cerulean};
      font-weight: 800;
      font-size: 14px;
      line-height: 20px;
      text-transform: uppercase;
    }
  }

  .sd-action-bar {
    padding: unset !important;
  }
  .sv-action__content {
    display: unset;
  }
  .sv-action {
    flex-grow: unset !important;
  }
  .sv-action--hidden {
    display: none;
  }
  .sd-root-modern.sd-root-modern--mobile
    .sd-table:not(.sd-matrix__table)
    tr::after {
    height: 0;
  }

  // rating
  .sd-scrollable-container:not(.sd-scrollable-container--compact) {
    margin: 0 auto;
  }

  .sd-rating {
    overflow: hidden;
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
    }
    span {
      display: none;
    }
  }
  .sd-rating__item {
    min-width: 34px;
    min-height: 34px;
    box-shadow: unset;
  }
  .sd-rating__item--selected {
    background: unset;
    border: unset;
  }

  .sd-rating__item--allowhover:hover,
  .sd-rating__item:focus-within {
    border: unset;
  }

  .sv-ranking {
    margin-left: 20px;
  }

  .sd-boolean__thumb {
    transition-duration: 0s;
    border-radius: 12px 0 0 12px;
    height: 100%;
    padding: 60px;
    padding-top: 29px;
    padding-bottom: 29px;
    background: linear-gradient(
      45deg,
      ${colors.cerulean} 0%,
      ${colors.areoBlue} 100%
    );
  }
  .sd-boolean--checked {
    .sd-boolean__thumb {
      border-radius: 0 12px 12px 0;
    }
  }
  .sd-boolean__label {
    padding: 60px;
    padding-top: 29px;
    padding-bottom: 29px;
    .sv-string-viewer {
      opacity: 0;
      display: inline-block;
      height: 42px;
    }
    position: relative;
    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .sd-boolean__thumb-text {
    .sv-string-viewer {
      opacity: 0;
      display: inline-block;
      height: 42px;
    }
    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .sd-boolean {
    padding: 0;
    border-radius: 12px;
    input {
      outline: none;
      border: none;
    }
    box-shadow: unset;
  }
  .sd-boolean__switch {
    padding: 0;
    border-radius: 12px;
    z-index: 1;
  }

  .progress-bar-wrapper {
    height: 6px;
    background-color: ${colors.whisper};
    border-radius: 16px;
    position: relative;
  }
  .progress-bar-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 6px;
    border-radius: 16px;
    background-color: ${colors.cloudBurst};
    transition: width 0.1s;
  }
`;
