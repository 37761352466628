/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/ban-ts-comment */

import React, {useEffect} from 'react';
import * as _ from 'lodash';

import {StylesManager, Model} from 'survey-core';
import {Survey} from 'survey-react-ui';

import 'survey-core/defaultV2.css';

StylesManager.applyTheme('defaultV2');

const SurveyBase = ({...restProps}) => {
  const RNPost = (dataStr: String) => {
    console.log({dataStr});
    try {
      // @ts-ignore
      window?.ReactNativeWebView?.postMessage(dataStr);
    } catch (e) {
      console.log(e);
    }
  };

  const survey = new Model(restProps.surveyJSON);

  survey.onComplete.add(() => {
    survey.hasErrors()
      ? RNPost(JSON.stringify({error: 'SURVEY_PARSE_ERROR'}))
      : RNPost(JSON.stringify({surveyResult: survey.data}));
  });
  survey.onAfterRenderPage.add(() => {
    progress();
    autoBlur();
    addStars();
    updateBooleanSwitch();
  });

  const progress = () => {
    const arr = [...document.getElementsByClassName('progress-bar-container')];
    if (arr.length > 0) {
      // @ts-ignore
      arr[0].style.width = `${survey.getProgress()}%`;
    } else {
      const progressDiv = document.createElement('div');
      progressDiv.className = 'progress-bar-wrapper';
      progressDiv.innerHTML = `<div class="progress-bar-container" style="width:${survey.getProgress()}%"></div>`;
      if (!!document.getElementsByClassName('sd-container-modern__title') && !!document.getElementsByClassName('sd-container-modern__title')[0]) {
      document
        ?.getElementsByClassName('sd-container-modern__title')[0]
        ?.appendChild(progressDiv);
      }
    }
  };
  const blur = () => {
    // @ts-ignore
    document?.activeElement?.blur();
  };
  const autoBlur = () => {
    const arr = [
      ...document.getElementsByClassName('sd-radio__control'),
      ...document.getElementsByClassName('sd-checkbox__control'),
    ];
    for (const key in arr) {
      if (typeof arr[key] === 'object')
        arr[key].addEventListener('click', blur);
    }
  };

  const addStars = () => {
    const arr = [...document.getElementsByClassName('sd-rating__item')];
    for (const key in arr) {
      if (typeof arr[key] === 'object') {
        const imgs = arr[key].getElementsByTagName('img');
        if (imgs.length === 0) {
          const img = document.createElement('img');
          img.id = 'img-star';
          img.src = '/images/star.png';
          arr[key].appendChild(img);
          img.addEventListener('click', e => {
            const selectedIndex = parseInt(key);
            for (const key in arr) {
              if (typeof arr[key] === 'object') {
                const imgs = arr[key].getElementsByTagName('img');
                imgs[0].src = `/images/star${
                  parseInt(key) > selectedIndex ? '' : '-selected'
                }.png`;
              }
            }
            blur();
          });
        }
      }
    }
  };

  const updateBooleanSwitch = () => {
    const arr = [...document.getElementsByClassName('sd-boolean')];
    for (const key in arr) {
      if (typeof arr[key] === 'object') {
        _.forEach(
          arr[key].getElementsByClassName('sd-boolean__label'),
          label => {
            const innerHtml =
              label.getElementsByClassName('sv-string-viewer')[0].innerHTML;
            const img = document.createElement('img');
            img.id = 'img-checkbox';
            img.src = `/images/${innerHtml === 'No' ? 'close' : 'check'}.png`;
            label.appendChild(img);
          }
        );
        arr[key].addEventListener('click', () => {
          setTimeout(() => {
            _.forEach(
              arr[key].getElementsByClassName('sd-boolean__thumb-text'),
              label => {
                const innerHtml =
                  label.getElementsByClassName('sv-string-viewer')[0].innerHTML;
                const imgs = label.getElementsByTagName('img');
                if (imgs[0]) label.removeChild(imgs[0]);
                const img = document.createElement('img');
                img.id = 'img-checkbox';
                img.src = `/images/${
                  innerHtml === 'No' ? 'close' : 'check'
                }-selected.png`;
                label.appendChild(img);
              }
            );
            setTimeout(() => {
              blur();
            }, 0);
          }, 0);
        });
      }
    }
  };

  return <Survey model={survey} />;
};

export default SurveyBase;
