const theme = {
  // "Name that Color" names
  colors: {
    primary: '#000000',
    black: '#000000',
    white: '#ffffff',
    cerulean: '#0DACE2',
    cloudBurst: '#1F2755',
    areoBlue: '#C3FDED',
    spray: '#7ADFEA',
    carnation: '#F54F4F',
    whisper: '#EAEBF4',
  },
  fonts: {
    face: {
      primary:
        'Nunito Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif',
      code: 'Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace',
    },
  },
} as const;

// Aliases
export const {colors, fonts} = theme;

export default theme;
