import React from 'react';

import * as Styled from './Survey.styles';
import SurveyBase from './SurveyBase';

const Survey = ({...props}) => {
  return (
    <main>
      <Styled.Content>
        {props?.surveyJSON ? (
          <SurveyBase surveyJSON={props?.surveyJSON} />
        ) : (
          'No survey JSON provided'
        )}
      </Styled.Content>
    </main>
  );
};
export default Survey;
